<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
<!--      <a-form-model-item prop="userId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.用户id')}}<template slot="title">{{$t('问答审核.用户id')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('问答审核.用户id')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="goodsId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.商品id')}}<template slot="title">{{$t('问答审核.商品id')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.goodsId"  :placeholder="$t('通用.输入.请输入')+$t('问答审核.商品id')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="type" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.1问  2答')}}<template slot="title">{{$t('问答审核.1问  2答')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop="content" >
        <span slot="label" >
          <a-tooltip>
            {{$t('内容')}}<template slot="title">{{$t('内容')}}</template>
          </a-tooltip>
        </span>
        <a-textarea v-model="form.content" />
      </a-form-model-item>
<!--      <a-form-model-item prop="parentId" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.答案关联问题id，问题为0')}}<template slot="title">{{$t('问答审核.答案关联问题id，问题为0')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.parentId"  :placeholder="$t('通用.输入.请输入')+$t('问答审核.答案关联问题id，问题为0')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="createBy" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.创建者')}}<template slot="title">{{$t('问答审核.创建者')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.createBy" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('问答审核.创建者')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="updateBy" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.更新人')}}<template slot="title">{{$t('问答审核.更新人')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.updateBy" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('问答审核.更新人')" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop="status" >
        <span slot="label" >
          <a-tooltip>
            {{$t('问答审核')}}<template slot="title">{{$t('问答审核')}}</template>
          </a-tooltip>
        </span>


          <a-select style="width: 100%" v-model="form.status" placeholder="请选择入驻类型">
              <a-select-option v-for="(item, index) in [{'status':1,'name':'审核中'},{'status':2,'name':'通过'},{'status':3,'name':'驳回'}]" :value="item.status"
                               :key="index">
                  {{ item.name }}
              </a-select-option>
          </a-select>
      </a-form-model-item>
<!--      <a-form-model-item prop="flag" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('问答审核.1不匿名评论 2匿名评论')}}<template slot="title">{{$t('问答审核.1不匿名评论 2匿名评论')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.flag"  :placeholder="$t('通用.输入.请输入')+$t('问答审核.1不匿名评论 2匿名评论')" />-->
<!--      </a-form-model-item>-->
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getQuestion, addQuestion, updateQuestion } from '@/api/question/question'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    Editor
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        goodsId: null,

        type: null,

        content: null,

        parentId: null,

        createBy: null,

        createTime: null,

        updateBy: null,

        status: 0,

        flag: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        goodsId: null,
        type: null,
        content: null,
        parentId: null,
        createBy: null,
        createTime: null,
        updateBy: null,
        status: 0,
        flag: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getQuestion({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateQuestion(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addQuestion(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
