import request from '@/utils/request'


// 查询问答审核列表
export function listQuestion(query) {
  return request({
    url: '/question/question/list',
    method: 'get',
    params: query
  })
}

// 查询问答审核分页
export function pageQuestion(query) {
  return request({
    url: '/question/question/page',
    method: 'get',
    params: query
  })
}

// 查询问答审核详细
export function getQuestion(data) {
  return request({
    url: '/question/question/detail',
    method: 'get',
    params: data
  })
}

// 新增问答审核
export function addQuestion(data) {
  return request({
    url: '/question/question/add',
    method: 'post',
    data: data
  })
}

// 修改问答审核
export function updateQuestion(data) {
  return request({
    url: '/question/question/edit',
    method: 'post',
    data: data
  })
}

// 删除问答审核
export function delQuestion(data) {
  return request({
    url: '/question/question/delete',
    method: 'post',
    data: data
  })
}
